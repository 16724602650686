<template>
  <section>
    <div class="product pageP">
      <div class="product_module moduleP">
        <div class="product_title">{{ $t("language.productCenter") }}</div>
        <div class="fx wp computer">
          <div
            v-for="(item, index) in dataInfo.item_list"
            @click="toproductDetail(item.id)"
            :key="index"
            class="product_item"
          >
            <img class="product_image" :src="item.image" alt="" />
            <div class="product_text fx">
              <div style="overflow: hidden; margin-right: 10px" class="f1 h1">
                <span v-show="$i18n.locale == 'zh'">{{ item.title }}</span>
                <span v-show="$i18n.locale == 'en'">{{ item.title_en }}</span>
              </div>
              <div>{{ $t("language.detailTitle") }}→</div>
            </div>
          </div>
        </div>
        <div class="phone">
          <div class="productP">
            <div
              v-for="(item, index) in dataInfo.item_list"
              :key="index"
              @click="toproductDetail(item.id)"
              class="productP_modle"
            >
              <img class="productP_img" :src="item.image" alt="" />
              <div class="productP_title h1">
                <span v-show="$i18n.locale == 'zh'">{{ item.title }}</span>
                <span v-show="$i18n.locale == 'en'">{{ item.title_en }}</span>
              </div>
            </div>
          </div>
        </div>
        <div @click="toproduct" class="product_more faj">
          {{ $t("language.productCenter") }}<a-icon type="right" />
        </div>
      </div>
    </div>

    <div class="company pageP">
      <div class="company_title">{{ $t("language.elegantTitle") }}</div>
      <div class="company_module fx computer">
        <div class="company_detail">
          <div class="company_text h1" style="overflow: hidden">
            {{ $t("language.elegantTitle") }}
          </div>
          <div class="company_content h11">
            <div
              v-if="dataInfo != '' && $i18n.locale == 'zh'"
              v-html="dataInfo.company_style_list[0].desc"
            ></div>
            <div
              v-if="dataInfo != '' && $i18n.locale == 'en'"
              v-html="dataInfo.company_style_list[0].desc_en"
            ></div>
          </div>
        </div>
        <div class="f1 fx wp">
          <div
            v-for="(item, index) in dataInfo.company_style_list"
            :key="index"
            class="company_item"
          >
            <img class="company_image" :src="item.image" alt="" />
            <div style="overflow: hidden" class="company_footer h1">
              <span v-show="$i18n.locale == 'zh'">{{ item.title }}</span>
              <span v-show="$i18n.locale == 'en'">{{ item.title_en }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="companyP phone">
        <div class="companyP_module">
          <div class="company_text h1" style="overflow: hidden">
            {{ $t("language.elegantTitle") }}
          </div>
          <div class="companyP_content">
            <div
              v-if="dataInfo != '' && $i18n.locale == 'zh'"
              v-html="dataInfo.company_style_list[0].desc"
            ></div>
            <div
              v-if="dataInfo != '' && $i18n.locale == 'en'"
              v-html="dataInfo.company_style_list[0].desc_en"
            ></div>
          </div>
        </div>
        <div
          v-for="(item, index) in dataInfo.company_style_list"
          :key="index"
          class="companyP_item"
        >
          <img class="company_image" :src="item.image" alt="" />
          <div style="overflow: hidden" class="company_footer h1">
            <span v-show="$i18n.locale == 'zh'">{{ item.title }}</span>
            <span v-show="$i18n.locale == 'en'">{{ item.title_en }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="about pageP">
      <div class="about_title">{{ $t("language.aboutUs") }}</div>
      <div class="about_module fx computer">
        <img
          class="about_image"
          v-if="dataInfo != ''"
          :src="dataInfo.agreement_info.image"
          alt=""
        />
        <div class="f1">
          <div v-if="dataInfo != ''" class="about_text">
            <span v-show="$i18n.locale == 'zh'">{{
              dataInfo.agreement_info.title
            }}</span>
            <span v-show="$i18n.locale == 'en'">{{
              dataInfo.agreement_info.title_en
            }}</span>
          </div>
          <div class="about_content">
            <div
              v-if="dataInfo != '' && $i18n.locale == 'zh'"
              v-html="dataInfo.agreement_info.content"
            ></div>
            <div
              v-if="dataInfo != '' && $i18n.locale == 'en'"
              v-html="dataInfo.agreement_info.content_en"
            ></div>
          </div>
          <div class="fx fed">
            <div @click="toabout" class="about_more faj">
              {{ $t("language.moreTitle") }}→
            </div>
          </div>
        </div>
      </div>
      <div class="aboutP phone">
        <img
          class="aboutP_image"
          v-if="dataInfo != ''"
          :src="dataInfo.agreement_info.image"
          alt=""
        />
        <div>
          <div v-if="dataInfo != ''" class="about_text">
            <span v-show="$i18n.locale == 'zh'">{{
              dataInfo.agreement_info.title
            }}</span>
            <span v-show="$i18n.locale == 'en'">{{
              dataInfo.agreement_info.title_en
            }}</span>
          </div>
          <div class="aboutP_content">
            <div
              v-if="dataInfo != '' && $i18n.locale == 'zh'"
              v-html="dataInfo.agreement_info.content"
            ></div>
            <div
              v-if="dataInfo != '' && $i18n.locale == 'en'"
              v-html="dataInfo.agreement_info.content_en"
            ></div>
          </div>
        </div>
        <div @click="toabout" class="active_btn faj">
          {{ $t("language.moreTitle") }}→
        </div>
      </div>
    </div>

    <div class="active pageP">
      <div class="active_title">{{ $t("language.newsTendency") }}</div>
      <div class="active_module computer">
        <div class="faj">
          <div class="fx">
            <div
              @click="tableClick('0')"
              class="active_table faj"
              :class="tableIndex == 0 ? 'active_active' : ''"
            >
              {{ $t("language.activeTitle") }}
            </div>
            <div
              @click="tableClick('1')"
              class="active_table faj"
              :class="tableIndex == 1 ? 'active_active' : ''"
            >
              {{ $t("language.noticeTitle") }}
            </div>
            <div
              @click="tableClick('2')"
              class="active_table faj"
              :class="tableIndex == 2 ? 'active_active' : ''"
            >
              {{ $t("language.tradeTitle") }}
            </div>
          </div>
        </div>
        <div class="fx">
          <div
            v-for="(item, index) in dataInfo.article_category_list"
            :key="index"
            class="fx"
          >
            <div
              v-for="(itemS, indexS) in item.list"
              :key="indexS"
              @click="tonewsDetail(itemS.id)"
              v-show="tableIndex == index"
              class="active_item"
            >
              <div class="active_date">{{ itemS.createtime }}</div>
              <div class="active_text">
                <span v-show="$i18n.locale == 'zh'">{{ itemS.title }}</span>
                <span v-show="$i18n.locale == 'en'">{{ itemS.title_en }}</span>
              </div>
              <div v-show="$i18n.locale == 'zh'" class="active_content h3">
                <div v-html="itemS.content"></div>
              </div>
              <div v-show="$i18n.locale == 'en'" class="active_content h3">
                <div v-html="itemS.content_en"></div>
              </div>
              <img class="active_image" :src="itemS.image" alt="" />
            </div>
          </div>
        </div>
        <div @click="tonews" class="active_btn faj">
          {{ $t("language.moreTitle") }}→
        </div>
      </div>
      <div class="activeP phone">
        <div
          v-for="(item, index) in dataInfo.article_category_list"
          :key="index"
        >
          <div
            v-for="(itemS, indexS) in item.list"
            :key="indexS"
            @click="tonewsDetail(itemS.id)"
            class="activeP_item fa"
          >
            <img :src="itemS.image" class="activeP_image" alt="" />
            <div class="activeP_data f1">
              <div class="activeP_title h1">
                <span v-show="$i18n.locale == 'zh'">{{ itemS.title }}</span>
                <span v-show="$i18n.locale == 'en'">{{ itemS.title_en }}</span>
              </div>
              <div class="activeP_text h2">
                <div v-show="$i18n.locale == 'zh'" v-html="itemS.content"></div>
                <div
                  v-show="$i18n.locale == 'en'"
                  v-html="itemS.content_en"
                ></div>
              </div>
              <div class="activeP_time">{{ itemS.createtime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contact pageP">
      <div class="contact_title">{{ $t("language.contactTitle") }}</div>
      <div class="contact_module computer">
        <div class="fx wp">
          <div class="contact_item fa">
            <span class="contact_important">*</span>{{ $t("language.name") }}:
            <input v-model="form.name" type="text" class="contact_input f1" />
          </div>
          <div class="contact_item fa">
            <span class="contact_important">*</span>{{ $t("language.phone") }}:
            <input v-model="form.phone" type="text" class="contact_input f1" />
          </div>
          <div class="contact_item fa">
            {{ $t("language.email") }}:
            <input v-model="form.email" type="text" class="contact_input f1" />
          </div>
          <div class="contact_item fa">
            {{ $t("language.address") }}:
            <input
              v-model="form.address"
              type="text"
              class="contact_input f1"
            />
          </div>
        </div>
        <div class="contact_content fx">
          {{ $t("language.words") }}：
          <textarea
            v-model="form.content"
            class="contact_area f1"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div @click="feedback" class="contact_submit faj">
          {{ $t("language.submit") }}
        </div>
      </div>
      <div class="contactP phone">
        <div class="contact_item contactP_item fa">
          <span class="contact_important">*</span>{{ $t("language.name") }}:
          <input v-model="form.name" type="text" class="contact_input f1" />
        </div>
        <div class="contact_item contactP_item fa">
          <span class="contact_important">*</span>{{ $t("language.phone") }}:
          <input v-model="form.phone" type="text" class="contact_input f1" />
        </div>
        <div class="contact_item contactP_item fa">
          {{ $t("language.email") }}:
          <input v-model="form.email" type="text" class="contact_input f1" />
        </div>
        <div class="contact_item contactP_item fa">
          {{ $t("language.address") }}:
          <input v-model="form.address" type="text" class="contact_input f1" />
        </div>
        <div class="contact_content contactP_item fx">
          {{ $t("language.words") }}：
          <textarea
            v-model="form.content"
            class="contact_area f1"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div @click="feedback" class="contact_submit faj">
          {{ $t("language.submit") }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import qs from "qs";
import { get_index_data, feedback } from "@/assets/api/api";
export default {
  data() {
    return {
      tableIndex: "0",
      dataInfo: "",
      form: {
        name: "",
        phone: "",
        email: "",
        address: "",
        content: "",
      },
    };
  },
  methods: {
    tonews() {
      this.$router.push("/news");
    },
    tonewsDetail(id) {
      this.$router.push({
        path: "/news",
        query: { id: id },
      });
    },
    toproduct() {
      this.$router.push("/product");
    },
    toproductDetail(id) {
      this.$router.push({
        path: "/product",
        query: { id: id },
      });
    },
    toabout() {
      this.$router.push("/about");
    },
    feedback() {
      if (this.form.name == "") {
        this.$message.error("请输入姓名");
      } else if (this.form.phone == "") {
        this.$message.error("请输入电话");
      } else {
        feedback(qs.stringify(this.form)).then((res) => {
          if (res.data.code == 1) {
            this.$message.success("提交成功");
            setTimeout(() => {
              this.form = {
                name: "",
                phone: "",
                email: "",
                address: "",
                content: "",
              };
            }, 1000);
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    tableClick(index) {
      this.tableIndex = index;
      this.$forceUpdate();
    },
    get_index_data() {
      get_index_data().then((res) => {
        if (res.data.code == 1) {
          this.dataInfo = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    backTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  created() {
    this.get_index_data();
  },
  mounted() {
    this.backTop();
  },
};
</script>

<style scoped>
.product {
  padding: 90px 0;
}
.product_module {
  width: 1200px;
  margin: 0 auto;
}
.product_title {
  font-size: 26px;
  color: #333;
  text-align: center;
  font-weight: bold;
}
.product_item {
  width: 280px;
  margin-right: 25px;
  margin-top: 30px;
  cursor: pointer;
}
.product_item:nth-child(4n) {
  margin-right: 0;
}
.product_image {
  width: 280px;
  height: 280px;
  box-sizing: border-box;
  border: 1px solid #fff;
  display: block;
}
.product_text {
  font-size: 17px;
  color: #333;
  padding: 25px 15px;
  overflow: hidden;
  background: #fff;
}
.product_item:hover .product_image {
  border: 1px solid #004e9f;
}
.product_item:hover .product_text {
  color: #fff;
  background: #004e9f;
}
.product_more {
  width: 145px;
  height: 40px;
  background: #004e9f;
  border-radius: 20px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin: 40px auto 0 auto;
}

.company {
  padding: 50px 0 80px 0;
  background: #f6f6f6;
}
.company_title {
  font-size: 26px;
  color: #333;
  text-align: center;
  font-weight: bold;
}
.company_module {
  width: 1200px;
  height: 420px;
  margin: 50px auto 0 auto;
}
.company_detail {
  position: relative;
  height: 420px;
  width: 560px;
  box-sizing: border-box;
  background: #fff;
  padding: 50px 40px;
  margin-right: 16px;
}
.company_text {
  font-size: 24px;
  color: #004e9f;
  font-weight: bold;
}
.company_content {
  font-size: 16px;
  color: #a8a8a8;
  overflow: hidden;
  margin-top: 15px;
}
.company_btn {
  width: 165px;
  height: 50px;
  margin-top: 20px;
  color: #004e9f;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid #004e9f;
  box-sizing: border-box;
}
.company_item {
  position: relative;
  height: 206px;
  width: 308px;
  margin-right: 8px;
  cursor: pointer;
  margin-bottom: 8px;
}
.company_image {
  width: 100%;
  height: 100%;
  display: block;
}
.company_footer {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
}
.company_item:nth-child(2n) {
  margin-right: 0;
}
.company_item:nth-child(3.4) {
  margin-bottom: 0;
}

.about {
  padding: 80px;
}
.about_title {
  font-size: 26px;
  color: #333;
  text-align: center;
  font-weight: bold;
}
.about_module {
  width: 1200px;
  margin: 50px auto 0 auto;
}
.about_image {
  width: 600px;
  display: block;
  margin-right: 25px;
}
.about_text {
  font-size: 26px;
  color: #333;
}
.about_content {
  font-size: 16px;
  margin-top: 30px;
  height: 300px;
  overflow: hidden;
}
.about_more {
  width: 165px;
  height: 50px;
  margin-top: 20px;
  color: #004e9f;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid #004e9f;
  box-sizing: border-box;
}

.active {
  padding: 80px 0 80px 0;
  background: linear-gradient(180deg, #e5ecff 0%, #ffffff 100%);
}
.active_title {
  font-size: 26px;
  color: #333;
  text-align: center;
  font-weight: bold;
}
.active_module {
  width: 1200px;
  margin: 50px auto 0 auto;
}
.active_table {
  cursor: pointer;
  width: 180px;
  height: 40px;
  font-size: 16px;
  color: #666;
  background: #fff;
  margin: 0 5px 30px 0;
}
.active_active {
  color: #fff;
  background: #004e9f;
}
.active_item {
  position: relative;
  width: 360px;
  margin: 0 17px;
  cursor: pointer;
  background: #fff;
}
.active_date {
  position: relative;
  padding: 20px;
  color: #666;
  border-bottom: 1px solid #f0f0f0;
  font-size: 24px;
}
.active_text {
  padding: 20px;
  font-size: 22px;
  color: #333;
}
.active_content {
  font-size: 14px;
  color: #999;
  padding: 0 20px;
  margin-bottom: 20px;
}
.active_image {
  height: 255px;
  width: 100%;
  display: block;
}
.active_btn {
  width: 165px;
  height: 50px;
  margin: 40px auto 0 auto;
  color: #004e9f;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid #004e9f;
  box-sizing: border-box;
}

.contact {
  padding: 80px 0;
  background: #f6f6f6;
}
.contact_title {
  font-size: 26px;
  color: #333;
  text-align: center;
  font-weight: bold;
}
.contact_module {
  width: 900px;
  margin: 50px auto 0 auto;
}
.contact_item {
  position: relative;
  width: 430px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0 25px;
  font-size: 14px;
  color: #333;
  margin-right: 40px;
  margin-bottom: 25px;
}
.contact_important {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 14px;
  width: 14px;
  left: 15px;
  color: #de0e0e;
}
.contact_input {
  border: none;
  background: none;
  font-size: 14px;
  margin-left: 10px;
}
.contact_item:nth-child(2n) {
  margin-right: 0;
}

.contact_content {
  position: relative;
  width: 900px;
  height: 104px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 15px 25px;
  font-size: 14px;
  color: #333;
  margin-right: 40px;
  margin-bottom: 25px;
}
.contact_area {
  border: none;
  background: none;
  font-size: 14px;
  margin-left: 10px;
}
.contact_submit {
  width: 120px;
  height: 40px;
  background: #004e9f;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  margin: 50px auto 0 auto;
}

.productP {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  display: block;
  padding: 30px 0 0 0;
}
.productP_modle {
  position: relative;
  margin-left: 20px;
  width: 150px;
  display: inline-block;
}
.productP_img {
  width: 100%;
  height: 150px;
  border-radius: 4px;
  display: block;
}
.productP_title {
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: #333;
  background: #eaeaea;
  padding: 5px 10px;
  overflow: hidden;
  margin-top: 10px;
}

.companyP {
  width: 90%;
  margin: 10px auto 0 auto;
  background: #fff;
}
.companyP_module {
  padding: 20px;
}
.companyP_content {
  font-size: 16px;
  color: #a8a8a8;
  overflow: hidden;
  margin-top: 15px;
}
.companyP_item {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.aboutP {
  padding: 20px;
}
.aboutP_image {
  width: 100%;
  display: block;
  margin-bottom: 15px;
}
.aboutP_content {
  font-size: 16px;
  margin-top: 30px;
  overflow: hidden;
}

.activeP {
  padding: 20px;
}
.activeP_item {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 14px rgba(0, 78, 159, 0.1);
  background: #fff;
}
.activeP_image {
  height: 88px;
  width: 88px;
  margin-right: 10px;
  display: block;
}
.activeP_data {
  overflow: hidden;
  box-sizing: border-box;
}
.activeP_title {
  font-size: 16px;
  color: #333;
}
.activeP_text {
  color: #999;
  font-size: 13px;
  overflow: hidden;
}
.activeP_time {
  color: #999;
  font-size: 13px;
  margin-top: 5px;
}
.contactP {
  padding: 20px;
}
.contactP_item {
  width: 100%;
  box-sizing: border-box;
}
</style>